import React from 'react';

import { CreateAccountForm } from '~/components/forms';
import SignInForm from '~/components/forms/sign-in-form';
import AuthenticationLayout from '~/layouts/authentication';

const SignIn: React.FC = () => {
  return (
    <AuthenticationLayout
      title="Sign In"
      primaryHeading="Login to Your Account"
      primaryDescription="Do you have teacher account? Use your credential to get access."
      primaryForm={<SignInForm primaryButton />}
      secondaryHeading="I Do not Have an Account"
      secondaryDescription="Create an account to have full access as a teacher"
      secondaryForm={<CreateAccountForm secondaryButton />}
    />
  );
};

export default SignIn;
