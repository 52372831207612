import React from 'react';

import SEO from '~/components/seo';
import { Heading } from '~/components/Typography';

import Layout from './main';
import styles from './styles.module.css'


interface AuthenticationLayoutProps {
  title: string;
  primaryHeading: string;
  primaryDescription: string;
  primaryForm: JSX.Element;
  secondaryHeading?: string;
  secondaryDescription?: string;
  secondaryForm?: JSX.Element;
}

const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({
  title,
  primaryHeading,
  primaryDescription,
  primaryForm,
  secondaryHeading,
  secondaryDescription,
  secondaryForm,
  children,
}) => {
  return (
    <Layout>
      <SEO title={title} />
      <section className="pt-10 lg:pb-20 px-10 lg:px-0 h-full bg-lessonBackground">
        <div className="flex flex-col lg:flex-row items-start justify-start w-full h-auto max-w-3xl m-auto xl:max-w-screen-lg">
          <div className="relative grid flex-col w-full gap-y-4">
            {children}
            <Heading color="ms" level={1} underline>
              {primaryHeading}
            </Heading>
            <div className="text-base text-gray-500">{primaryDescription}</div>
            {primaryForm}
          </div>
          <div className={`${styles.Line} h-full mx-24 hidden lg:flex lg:my-12 justify-self-center bg-purple-50`}/>
          {secondaryForm && (
            <div className="relative grid flex-col w-full gap-y-4 my-10 lg:mt-0">
              <div>
                <Heading color="ms" level={2}>
                  {secondaryHeading}
                </Heading>
                <div className="text-base text-gray-500">{secondaryDescription}</div>
              </div>
              {secondaryForm}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default AuthenticationLayout;
