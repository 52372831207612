import React, { FormEvent, useState, useEffect, useContext } from "react";
import { Link, navigate } from "gatsby";

import { InputField } from "~/components/Inputs";
import Button from "~/components/button";
import { MainContext } from "~/context";

import { Form } from "~/components/forms/form";


interface SignInFormProps {
  primaryButton?: boolean;
}

interface ValidationValues {
  email?: string | undefined;
  password?: string | undefined;
}

export const SignInForm = ({ primaryButton = false }: SignInFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getUser, loginUser } = useContext(MainContext);

  const [initialValues, setValues] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState<ValidationValues>({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (errors?.email || errors?.password) {
      return setErrors({});
    }
  }, [initialValues?.email, initialValues.password]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    return setValues({ ...initialValues, [name]: value });
  };

  const validateForm = () => {
    Object.entries(initialValues).forEach(([key, value]) => {
      if (!value) {
        setErrors((prevState) => {
          return { ...prevState, [key]: "Field can't be empty" };
        });
      } else {
        setErrors((prevState) => {
          return { ...prevState, [key]: "" };
        });
      }
    });
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    validateForm()

    if (!initialValues.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(initialValues.email)) {
      return setErrors({
        email: 'E-mail has a wrong format'
      })
    }

    if (!initialValues.email || !initialValues.password) return;
    setLoading(true);

    try {
      await loginUser(initialValues.email, initialValues.password);
      await getUser();
      setLoading(false);

      const path: string | null = localStorage.getItem("requestedPage");
      navigate(path || '/dashboard');
    } catch (error) {
      setLoading(false)
      setErrors({
        email: 'Wrong credentials',
      })
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        className="mt-4"
        onChange={handleChange}
        label="E-mail Address"
        name="email"
        value={initialValues.email}
        error={errors?.email}
      />
      <InputField
        className="mt-6"
        error={errors?.password}
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={initialValues.password}
      >
        <p className="text-gray-500 text-sm">
          I forgot my password.
          <Link className="text-purple-600" to="/reset-password">&nbsp;Reset password</Link>
        </p>
      </InputField>
      <Button
        action={primaryButton ? "primary" : "secondary"}
        className="mt-8"
        color="purple"
        loading={loading}
        style={{ minWidth: "180px" }}
        title="Sign in"
        type="submit"
      >
        Sign in
      </Button>
    </Form>
  );
};

export default SignInForm;
